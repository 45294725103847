import React from 'react';
import {BrowserRouter, Routes, Route} from "react-router-dom";
// Context
import {ErrorBoundaryProvider, PackagingUiProvider, UserProvider, PackagingDataProvider, ModuleProvider} from "@context";
// Pages
import Main from "@/pages/Main";
import BrandBuilder from "@/pages/BrandBuilder/BrandBuilder";
import RangeBuilder from "@/pages/RangeBuilder/RangeBuilder";
import ProductBuilder from "@/pages/ProductBuilder/ProductBuilder";
import AppBuilder from "@/pages/AppBuilder/AppBuilder";
import NotFound from "@/pages/NotFound";
// globals
import 'App.css'

const {REACT_APP_DASHBOARD_URL} = process.env

function App() {
    const resizeObserver = new ResizeObserver(async (entries) => {
        let detectedHeight = entries[0].target.clientHeight
        resize(detectedHeight)
    })

    resizeObserver.observe(document.body)

    const resize = (size: number) => {
        let payload = JSON.stringify(["resize", size])
        window.top?.postMessage(payload, `${REACT_APP_DASHBOARD_URL}`)
    }

  return (
      <>
        <ErrorBoundaryProvider>
            <UserProvider>
                <ModuleProvider>
                    <PackagingDataProvider>
                        <PackagingUiProvider>
                            <BrowserRouter>
                                <Routes>
                                    {/* BRANDS */}
                                    <Route path={"/brands/create"} element={<BrandBuilder creating />} />
                                    <Route path={"/brands/edit"} element={<BrandBuilder editing />} />
                                    {/* RANGES */}
                                    <Route path={"/ranges/create"} element={<RangeBuilder creating />} />
                                    <Route path={"/ranges/edit"} element={<RangeBuilder editing />} />
                                    {/* PRODUCTS*/}
                                    <Route path={"/products/create"} element={<ProductBuilder creating />} />
                                    <Route path={"/products/gs1/create"} element={<ProductBuilder creating gs1 />} />
                                    <Route path={"/products/edit"} element={<ProductBuilder editing />} />
                                    <Route path={"/products/gs1/edit"} element={<ProductBuilder editing gs1 />} />
                                    {/* APPS */}
                                    <Route path={"/apps/edit"} element={<AppBuilder editing />} />
                                    {/* MAIN */}
                                    <Route path={"/"} element={<Main />} />
                                    {/* FALLBACK */}
                                    <Route path={"*"} element={<NotFound />} />
                                </Routes>
                            </BrowserRouter>
                        </PackagingUiProvider>
                    </PackagingDataProvider>
                </ModuleProvider>
            </UserProvider>
        </ErrorBoundaryProvider>
      </>
  );
}

export default App;
